import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../FirebaseAuth";
import { userSignOut } from "../../../libs/user";
import { IconButton, Menu, MenuItem, Avatar, Divider } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PaidIcon from "@mui/icons-material/Paid";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Firestore } from "../../FirebaseAuth/firebase";
import { isAdmin, isKWALLAdmin } from "../../../utils";
import HelpIcon from "@mui/icons-material/Help";
import { collection, doc, getDocs, query, orderBy, getDoc, setDoc, updateDoc, where } from "firebase/firestore";

const UserMenu = () => {
  const initialUserState = {
    isLoading: true,
    data: null,
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { authUser } = useContext(AuthContext);
  const history = useNavigate();

  const [userData, setUserData] = useState(initialUserState);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getUserData = useCallback(async () => {
    try {
      // const refUser = await Firestore.collection("users")
      //   .doc(authUser.user.uid)
      //   .get();
      const userDocRef = doc(Firestore, "users", authUser.user.uid);
      const refUser = await getDoc(userDocRef);
      const userData = await refUser.data();
      setUserData({
        data: userData,
        isLoading: false,
      });
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }, [authUser]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <>
      <AuthContext.Consumer>
        {(context) => (
          <>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar
                alt={context.authUser.user.displayName}
                src={context.authUser.user.photoURL}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {!userData.isLoading &&
                isKWALLAdmin(userData.data?.role) &&
                isAdmin(userData.data?.role) && (
                  [
                    <MenuItem
                      key="organization-list"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                        history("/organization_list");
                      }}
                    >
                      <ApartmentIcon style={{ marginRight: "10px" }} />
                      Organization Lists
                    </MenuItem>,
                    <MenuItem
                      key="plan-list"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                        history("/planLists");
                      }}
                    >
                      <PaidIcon style={{ marginRight: "10px" }} />
                      Plan Lists
                    </MenuItem>,
                    <MenuItem
                      key="service-list"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                        history("/serviceLists");
                      }}
                    >
                      <SupportAgentIcon style={{ marginRight: "10px" }} />
                      Service Lists
                    </MenuItem>,
                    <Divider key="admin-divider" />
                  ]
                )}

              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                  history("/user/profile");
                }}
              >
                <AccountBoxIcon style={{ marginRight: "10px" }} />
                Profile
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                  history("/user/organization");
                }}
              >
                <ApartmentIcon style={{ marginRight: "10px" }} />
                Organization
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                  history("/user/log");
                }}
              >
                <ListAltIcon style={{ marginRight: "10px" }} />
                Activity Logs
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={(e) => {
                  window.open("https://help.kwallcompany.com/", "_blank");
                }}
              >
                <HelpIcon style={{ marginRight: "10px" }} />
                Support
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  userSignOut();
                  history("/welcome");
                }}
              >
                <ExitToAppIcon style={{ marginRight: "10px" }} />
                Sign Out
              </MenuItem>
            </Menu>
          </>
        )}
      </AuthContext.Consumer>
    </>
  );
};

export default UserMenu;
