import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form } from "../../../../components/Form";
import { makeStyles } from "@mui/styles";
import { isKWALLAdmin } from "../../../../utils";
import {
  accountTypesArr,
  fontArray,
  termsUrl,
} from "../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: "12px 0",
  },
  customFont: {
    fontFamily: "inherit",
  },
  Manuale: {
    fontFamily: "Manuale",
  },
  "Playfair Display": {
    fontFamily: "Playfair Display",
  },
  "PT Serif": {
    fontFamily: "PT Serif",
  },
  Roboto: {
    fontFamily: "Roboto",
  },
  "Roboto Condensed": {
    fontFamily: "Roboto Condensed",
  },
  Montserrat: {
    fontFamily: "Montserrat",
  },
  "Open Sans": {
    fontFamily: "Open Sans",
  },
  Jost: {
    fontFamily: "Jost",
  },
}));

export const OrganizationForm = ({
  handleSubmit,
  organizationData,
  inSubmit,
  handleChange,
  userData,
}) => {
  const classes = useStyles();
  return (
    <Form
      handleSubmit={(e) => handleSubmit(e)}
      disabled={
        !organizationData.name ||
        !organizationData.description ||
        !organizationData.accountType ||
        inSubmit ||
        !organizationData.acceptTerms
      }
      inSubmit={inSubmit}
      enableDefaultButtons={true}
    >
      <TextField
        label="Organization Name"
        type="text"
        value={organizationData.name}
        name="name"
        required={true}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        className={classes.textField}
      />
      <FormControl fullWidth className={classes.textField}>
        <InputLabel id="accountType">Organization Type</InputLabel>
        <Select
          labelId="accountType"
          name="accountType"
          type="text"
          required={true}
          value={organizationData.accountType}
          onChange={handleChange}
          label="Organization Type"
        >
          {accountTypesArr?.map((accType) => (
            <MenuItem value={accType} key={accType}>
              {accType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Description"
        type="text"
        value={organizationData.description}
        name="description"
        required={true}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        className={classes.textField}
      />
      <TextField
        label="3-4 letter Org Code"
        type="text"
        value={organizationData.organizationCode}
        name="organizationCode"
        required={true}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        className={classes.textField}
      />
      <TextField
        label="Primary Color"
        placeholder=""
        type="color"
        value={organizationData.primaryColor || "#000000"}
        name="primaryColor"
        required={true}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        className={`${classes.textField} ColorInput`}
      />
      <TextField
        label="Secondary Color"
        placeholder=""
        type="color"
        value={organizationData.secondaryColor || "#000000"}
        name="secondaryColor"
        required={true}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        className={`${classes.textField} ColorInput`}
      />

      <FormControl fullWidth className={classes.textField}>
        <InputLabel id="primaryFont">Primary Font</InputLabel>
        <Select
          labelId="primaryFont"
          name="primaryFont"
          type="text"
          required={true}
          value={organizationData.primaryFont || fontArray[0]}
          onChange={handleChange}
          label="Primary Font"
        >
          {fontArray?.map((fontType) => (
            <MenuItem
              value={fontType}
              key={fontType}
              className={`${classes[fontType]} ${classes.customFont}`}
            >
              <div className={`${classes[fontType]} ${classes.customFont}`}>
                {fontType}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth className={classes.textField}>
        <InputLabel id="secondaryFont">Secondary Font</InputLabel>
        <Select
          labelId="secondaryFont"
          name="secondaryFont"
          type="text"
          required={true}
          value={organizationData.secondaryFont || fontArray[0]}
          onChange={handleChange}
          label="Secondary Font"
        >
          {fontArray?.map((fontType) => (
            <MenuItem
              value={fontType}
              key={fontType}
              className={`${classes[fontType]} ${classes.customFont}`}
            >
              <div className={`${classes[fontType]} ${classes.customFont}`}>
                {fontType}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth className={classes.textField}>
        <InputLabel id="tertiaryFont">Tertiary Font</InputLabel>
        <Select
          labelId="tertiaryFont"
          name="tertiaryFont"
          type="text"
          required={true}
          value={organizationData.tertiaryFont || fontArray[0]}
          onChange={handleChange}
          label="Tertiary Font"
        >
          {fontArray?.map((fontType) => (
            <MenuItem
              value={fontType}
              key={fontType}
              className={`${classes[fontType]} ${classes.customFont}`}
            >
              <div className={`${classes[fontType]} ${classes.customFont}`}>
                {fontType}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Primary Website"
        type="text"
        id="primaryUrl"
        value={organizationData.primaryUrl}
        name="primaryUrl"
        onChange={handleChange}
        fullWidth
        required={false}
        variant="outlined"
        className={classes.textField}
      />

      {isKWALLAdmin(userData?.role) && (
        <TextField
          label="Pantheon Organization ID"
          type="text"
          value={organizationData.pantheonOrgID}
          name="pantheonOrgID"
          required={false}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          className={classes.textField}
        />
      )}
      {isKWALLAdmin(userData?.role) && (
        <TextField
          label="Machine Token"
          type="text"
          value={organizationData.machineToken}
          name="machineToken"
          required={false}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          className={classes.textField}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={organizationData.acceptTerms}
            onClick={() =>
              handleChange({
                target: {
                  name: "acceptTerms",
                  value: !organizationData.acceptTerms,
                },
              })
            }
            size="small"
          />
        }
        label={
          <Button
            href={termsUrl}
            target="_blank"
            style={{
              textDecorationColor: "#0A79A6",
              color: "#0A79A6",
            }}
          >
            <Box>I Agree to Terms &amp; Conditions</Box>
          </Button>
        }
      />
    </Form>
  );
};
