import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/FirebaseAuth";
import PublicRouter from "./components/routers/PublicRouter";
import PublicTemplate from "./components/templates/PublicTemplate";
import AccountTemplate from "./components/templates/AccountTemplate";

import AuthRouter from "./components/routers/AuthRouter";

import TermsAndConditions from "./pages/public/TemsandConditions";
import Home from "./pages/auth/Home";
import PageNotFound from "./pages/public/PageNotFound";
import AppTemplate from "./components/templates/AppTemplate";
import UserProfile from "./pages/auth/user/UserProfile";
import Organization from "./pages/auth/Organization";
import UpdateOrganization from "./pages/auth/Organization/UpdateOrganization";
import UpdateEmail from "./pages/auth/user/UpdateEmail";
import UpdateName from "./pages/auth/user/UpdateName";
import VerifyEmail from "./pages/auth/user/VerifyEmail";
import UpdatePassword from "./pages/auth/user/UpdatePassword";
import UpdatePhone from "./pages/auth/user/UpdatePhone";
import DeleteUser from "./pages/auth/user/DeleteUser";
import ViewLogs from "./pages/auth/user/ViewLogs";
import Plans from "./pages/auth/accounts/Plans";
import NewAccount from "./pages/auth/accounts/NewAccount";
import NewOrganization from "./pages/auth/Organization/NewOrganization";
import AccountSubscription from "./pages/auth/accounts/Subscriptions";
import TemplateListGlobal from "./pages/auth/Templates/TemplateList";
import TemplateCreateGlobal from "./pages/auth/Templates/TemplateCreate";
import TemplateEditGlobal from "./pages/auth/Templates/TemplateEdit";
import EditTemplatesFilters from "./pages/auth/accounts/Sites/EditTemplateFilters";

// load stripe
// import { stripeConfig } from "./inc/stripe.json";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Overview from "./pages/auth/accounts/Overview";
import UserList from "./pages/auth/accounts/UserList";
import UserRole from "./pages/auth/accounts/UserRole";
import InvoiceDetails from "./pages/auth/accounts/InvoiceDetails";
import MonthlyInvoice from "./pages/auth/accounts/MonthlyInvoice";
import AddUser from "./pages/auth/accounts/AddUser";
import Invite from "./pages/auth/user/Invite";
import PaymentList from "./pages/auth/accounts/PaymentList";
import PaymentMethod from "./pages/auth/accounts/PaymentMethod";
import DeleteAccount from "./pages/auth/accounts/DeleteAccount";
import ManageEnvironment from "./pages/auth/accounts/ManageEnvironment";
import SiteList from "./pages/auth/accounts/Sites/SiteList";
import SiteDetails from "./pages/auth/accounts/Sites/SiteDetails";
import AccountOverview from "./pages/auth/accounts/AccountOverview";
import SiteDomainEdit from "./pages/auth/accounts/Sites/SitedomainEdit";
import SiteCreate from "./pages/auth/accounts/Sites/SiteCreate";
import SiteEdit from "./pages/auth/accounts/Sites/SiteEdit";
import SitesListWithTemplates from "./pages/auth/accounts/sitesWithTemplates/SiteList";
import TemplateList from "./pages/auth/accounts/Templates/TemplateList";
import TemplateCreate from "./pages/auth/accounts/Templates/TemplateCreate";
import TemplateEdit from "./pages/auth/accounts/Templates/TemplateEdit";
import SitesList from "./pages/auth/sites/SitesList";
import AddSite from "./pages/auth/sites/AddSite";
import PlanLists from "./pages/auth/planLists";

import "./App.css";
import EditPlan from "./pages/auth/planLists/EditPlan";
import AddPlan from "./pages/auth/planLists/AddPlan";
import { ServiceRequest } from "./pages/auth/accounts/Sites/ServiceRequest";
import ServiceLists from "./pages/auth/ServiceLists";
import AddService from "./pages/auth/ServiceLists/AddService";
import EditService from "./pages/auth/ServiceLists/EditService";
import OrganizationList from "./pages/auth/OrganizationList/OrganizationList";
import OrganizationSwitch from "./pages/auth/Organization/OrganizationSwitch";
import OrganizationDetails from "./pages/auth/OrganizationList/OrganizationDetails";
import CreateOrganization from "./pages/auth/OrganizationList/CreateOrganization";
import OrganizationAddUser from "./pages/auth/Organization/AddUser";
import OrganizationUserList from "./pages/auth/Organization/UserList";
import OrganizationUserRole from "./pages/auth/Organization/UserRole";
import { AuthTemplate } from "./components/templates/AuthTemplate";
import { SignIn } from "./pages/public/SignIn/SignIn";
import Welcome from "./pages/public/Welcome/Welcome";
import { OrganizationSiteList } from "./pages/auth/Organization/OrganizationSiteList";
import { Marketplace } from "./pages/auth/accounts/Marketplace/Marketplace";
import InviteRouter from "./components/routers/InviteRouter";
import SiteImport from "./pages/auth/accounts/Sites/SiteImport";

const stripePromise = loadStripe(
  process.env.REACT_APP_PUBLIC_API_KEY
);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <AuthRouter
                  component={Home}
                  template={AppTemplate}
                  title="My Accounts"
                />
              }
            />
            <Route
              path="/marketplace"
              element={
                <AuthRouter
                  component={Marketplace}
                  template={AppTemplate}
                  title="Marketplace"
                />
              }
            />
            <Route
              path="/terms"
              element={
                <PublicRouter
                  component={TermsAndConditions}
                  template={PublicTemplate}
                  title="Terms & Conditions"
                />
              }
            />

            <Route
              path="/marketplace/request/:serviceId"
              element={
                <AuthRouter
                  exact
                  path="/marketplace/request/:serviceId"
                  component={ServiceRequest}
                  template={AppTemplate}
                  title="Request Services"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            <Route
              path="/account-overview/:accountId"
              element={
                <AuthRouter
                  exact
                  path="/account-overview/:accountId"
                  component={AccountOverview}
                  template={AppTemplate}
                  title="Account Overview"
                />
              }
            />

            <Route
              path="/account/:accountId/sites/edit/:siteId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/sites/edit/:siteId"
                  component={SiteEdit}
                  template={AccountTemplate}
                  title="Edit Site"
                  role="*"
                />
              }
            />

            <Route
              path="/account/:accountId/sites/create"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/sites/create"
                  component={SiteCreate}
                  template={AccountTemplate}
                  title="Create Site"
                  role="*"
                />
              }
            />
            <Route
              path="/account/:accountId/sites/import"
              element={
              <AuthRouter
                exact
                path="/account/:accountId/sites/import"
                component={SiteImport}
                template={AccountTemplate}
                title="Create Site"
                role="*"
              />
              }
            />
            <Route
              path="/account/:accountId/site/:siteId/edit-choosen-temp"
              element={<AuthRouter
                exact
                path="/account/:accountId/site/:siteId/edit-choosen-temp"
                component={EditTemplatesFilters}
                template={AccountTemplate}
                title="Edit Templates"
                role="*"
              />
              }
            />
            <Route 
              path="/account/:accountId/sites"
              element={
                <AuthRouter
                exact
                path="/account/:accountId/sites"
                component={SiteList}
                template={AccountTemplate}
                title="Sites List"
                role="*"
                allowInactive={true}
              />
              }
            />
            <Route 
              path="/account/:accountId/invoice-details/:siteId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/invoice-details/:siteId"
                  component={InvoiceDetails}
                  template={AccountTemplate}
                  title="Invoice Details"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            <Route 
              path="/account/:accountId/monthly-invoices-details/:siteId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/monthly-invoices-details/:siteId"
                  component={MonthlyInvoice}
                  template={AccountTemplate}
                  title="Invoice Details"
                  role="*"
                  allowInactive={true}
                />
                
              }
            />
            <Route 
              path="/account/:accountId/also-templates"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/also-templates"
                  component={SitesListWithTemplates}
                  template={AccountTemplate}
                  title="Sites List with Templates"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            <Route 
              path="/account/:accountId/site-details/:siteId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/site-details/:siteId"
                  component={SiteDetails}
                  template={AccountTemplate}
                  title="Site Details"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            
            <Route 
              path="/account/:accountId/site/:siteId/edit-domain"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/site/:siteId/edit-domain"
                  component={SiteDomainEdit}
                  template={AccountTemplate}
                  title="Edit Site Domain"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            <Route 
              path="/account/:accountId/service/:serviceId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/service/:serviceId"
                  component={ServiceRequest}
                  template={AccountTemplate}
                  title="Request Services"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            <Route 
              path="/account/:accountId/templates/edit/:tempId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/templates/edit/:tempId"
                  component={TemplateEdit}
                  template={AccountTemplate}
                  title="Edit Template"
                  role="*"
                />
              }
            />
            <Route 
              path="/account/:accountId/manage-environment/:siteId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/manage-environment/:siteId"
                  component={ManageEnvironment}
                  template={AccountTemplate}
                  title="Edit Template"
                  role="*"
                />
              }
            />
            <Route 
              path="/account/:accountId/templates/create"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/templates/create"
                  component={TemplateCreate}
                  template={AccountTemplate}
                  title="Create Template"
                  role="*"
                />
              }
            />
            <Route 
              path="/account/:accountId/templates"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/templates"
                  component={TemplateList}
                  template={AccountTemplate}
                  title="Templates"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            <Route 
              path="/account/:accountId/billing/plan/:siteId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/billing/plan/:siteId"
                  component={Plans}
                  template={AccountTemplate}
                  title="Select Plan"
                  role="*"
                  allowInactive={true}
                />
              }
            />
            <Route 
              path="/account/:accountId/billing/payment-method"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/billing/payment-method"
                  component={PaymentMethod}
                  template={AccountTemplate}
                  title="Update Payment Method"
                  role="*"
                />
              }
            />
            <Route 
              path="/account/:accountId/billing/delete"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/billing/delete"
                  component={DeleteAccount}
                  template={AccountTemplate}
                  title="Delete Account"
                  role="*"
                />
              }
            />
            <Route 
              path="/account/:accountId/users/change/:userId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/users/change/:userId"
                  component={UserRole}
                  template={AccountTemplate}
                  title="Manage User"
                  role="admin"
                />
              }
            />
            <Route 
              path="/account/:accountId/users"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/users"
                  component={UserList}
                  template={AccountTemplate}
                  title="Users"
                  role="admin"
                />
              }
            />
            <Route 
              path="/account/:accountId/users/add"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/users/add"
                  component={AddUser}
                  template={AccountTemplate}
                  title="Add User"
                  role="admin"
                />
              }
            />
            <Route 
              path="/account/:accountId/billing"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/billing"
                  component={PaymentList}
                  template={AccountTemplate}
                  title="Billing"
                  role="admin"
                />
              }
            />
            <Route 
              path="/account/:accountId"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/"
                  component={Overview}
                  template={AccountTemplate}
                  title="Overview"
                  role="*"
                />
              }
            />
            <Route 
              path="/new-account/:accountId?"
              element={
                <AuthRouter
                  exact
                  path="/new-account/:accountId?"
                  component={NewAccount}
                  template={AppTemplate}
                  title="Create New Account"
                />
              }
            />
            <Route 
              path="/new-organization"
              element={
                <AuthRouter
                  exact
                  path="/new-organization"
                  component={NewOrganization}
                  template={AppTemplate}
                  title="Create New Organization"
                />
              }
            />
            <Route 
              path="/user/profile"
              element={
                <AuthRouter
                  exact
                  path="/user/profile"
                  component={UserProfile}
                  template={AppTemplate}
                  title="User Profile"
                />
              }
            />
            <Route 
              path="/user/organization"
              element={
                <AuthRouter
                  exact
                  path="/user/organization"
                  component={Organization}
                  template={AppTemplate}
                  title="My Organization"
                />
              }
            />
            <Route 
              path="/planLists"
              element={
                <AuthRouter
                  exact
                  path="/planLists"
                  component={PlanLists}
                  template={AppTemplate}
                  title="Plan Lists"
                  role="kwallAdmin"
                />
              }
            />
            <Route 
              path="/planLists/edit/:planId"
              element={
                <AuthRouter
                  exact
                  path="/planLists/edit/:planId"
                  component={EditPlan}
                  template={AppTemplate}
                  title="Edit Plan"
                  role="kwallAdmin"
                />
              }
            />
            <Route 
              path="/planLists/create"
              element={
                <AuthRouter
                  exact
                  path="/planLists/create"
                  component={AddPlan}
                  template={AppTemplate}
                  title="Create New Plan"
                  role="kwallAdmin"
                />}
            />
            <Route 
              path="/serviceLists"
              element={
                <AuthRouter
                  exact
                  path="/serviceLists"
                  component={ServiceLists}
                  template={AppTemplate}
                  title="Service Lists"
                  role="kwallAdmin"
                />}
            />
            <Route 
              path="/serviceLists/edit/:serviceId"
              element={
                <AuthRouter
                  exact
                  path="/serviceLists/edit/:serviceId"
                  component={EditService}
                  template={AppTemplate}
                  title="Edit Service"
                  role="kwallAdmin"
                />}
            />
            <Route 
              path="/serviceLists/create"
              element={
                <AuthRouter
                  exact
                  path="/serviceLists/create"
                  component={AddService}
                  template={AppTemplate}
                  title="Create New Service"
                  role="kwallAdmin"
                />}
            />
            <Route 
              path="/organization_list/detail/:organizationId"
              element={
                <AuthRouter
                  exact
                  path="/organization_list/detail/:organizationId"
                  component={OrganizationDetails}
                  template={AppTemplate}
                  title="Organization Details"
                />}
            />
            <Route 
              path="/organization_list/sites/:organizationId"
              element={
                <AuthRouter
                  exact
                  path="/organization_list/sites/:organizationId"
                  component={OrganizationSiteList}
                  template={AppTemplate}
                  title="Organization Site List"
                />}
            />
            <Route 
              path="/organization_list/create"
              element={
                <AuthRouter
                  exact
                  path="/organization_list/create"
                  component={CreateOrganization}
                  template={AppTemplate}
                  title="Create New Service"
                />}
            />
            <Route 
              path="/organization_list/edit/:organizationId"
              element={
                <AuthRouter
                  exact
                  path="/organization_list/edit/:organizationId"
                  component={UpdateOrganization}
                  template={AppTemplate}
                  title="Create New Service"
                />}
            />
            <Route 
              path="/organization/switch-organization"
              element={
                <AuthRouter
                  exact
                  path="/organization/switch-organization"
                  component={OrganizationSwitch}
                  template={AppTemplate}
                  title="Switch Active Organization"
                />}
            />
            <Route 
              path="/organization_list"
              element={
                <AuthRouter
                  exact
                  path="/organization_list"
                  component={OrganizationList}
                  template={AppTemplate}
                  title="Organizations"
                  role="kwallAdmin"
                />}
            />
            <Route 
              path="/user/organization/:organizationId"
              element={
                <AuthRouter
                  exact
                  path="/user/organization/:organizationId"
                  component={UpdateOrganization}
                  template={AppTemplate}
                  title="Edit Organization"
                  role="admin"
                />}
            />
            <Route 
              path="/organization/:organizationId/users/change/:userId"
              element={
                <AuthRouter
                  exact
                  path="/organization/:organizationId/users/change/:userId"
                  component={OrganizationUserRole}
                  template={AppTemplate}
                  title="Manage User"
                  role="admin"
                />}
            />
            <Route 
              path="/organization/:organizationId/users"
              element={
                <AuthRouter
                  exact
                  path="/organization/:organizationId/users"
                  component={OrganizationUserList}
                  template={AppTemplate}
                  title="Users"
                  role="admin"
                />}
            />
            <Route 
              path="/organization/:organizationId/users/add"
              element={
                <AuthRouter
                  exact
                  path="/organization/:organizationId/users/add"
                  component={OrganizationAddUser}
                  template={AppTemplate}
                  title="Add User"
                  role="admin"
                />}
            />
            <Route 
              path="/global-templates-list"
              element={
                <AuthRouter
                  exact
                  path="/global-templates-list"
                  component={TemplateListGlobal}
                  template={AppTemplate}
                  title="Template List"
                  role="kwallAdmin"
                />}
            />
            <Route 
              path="/global-template-edit/:tempId"
              element={
                <AuthRouter
                  exact
                  path="/global-template-edit/:tempId"
                  component={TemplateEditGlobal}
                  template={AppTemplate}
                  title="Template Edit"
                  role="kwallAdmin"
                />}
            />
            <Route 
              path="/global-template-create"
              element={
                <AuthRouter
                exact
                path="/global-template-create"
                component={TemplateCreateGlobal}
                template={AppTemplate}
                title="Template Create"
                role="kwallAdmin"
              />}
            />
            <Route 
              path="/invite/:code"
              element={
                <InviteRouter
                  exact
                  path="/invite/:code"
                  component={Invite}
                  template={AppTemplate}
                  title="View Invite"
                />}
            />
            <Route 
              path="/user/profile/update-email"
              element={
                <AuthRouter
                  exact
                  path="/user/profile/update-email"
                  component={UpdateEmail}
                  template={AppTemplate}
                  title="Change Your Email"
                />}
            />
            <Route 
              path="/user/profile/update-name"
              element={
                <AuthRouter
                  exact
                  path="/user/profile/update-name"
                  component={UpdateName}
                  template={AppTemplate}
                  title="Change Your Name"
                />}
            />
            <Route 
              path="/user/profile/verify-email"
              element={
                <AuthRouter
                  exact
                  path="/user/profile/verify-email"
                  component={VerifyEmail}
                  template={AppTemplate}
                  title="Verify Your Name"
                />}
            />
            <Route 
              path="/user/profile/update-password"
              element={
                <AuthRouter
                  exact
                  path="/user/profile/update-password"
                  component={UpdatePassword}
                  template={AppTemplate}
                  title="Change Your Password"
                />}
            />
            <Route 
              path="/user/profile/update-phone"
              element={
                <AuthRouter
                  exact
                  path="/user/profile/update-phone"
                  component={UpdatePhone}
                  template={AppTemplate}
                  title="Change Your Phone Number"
                />}
            />
            <Route 
              path="/user/profile/delete"
              element={
                <AuthRouter
                  exact
                  path="/user/profile/delete"
                  component={DeleteUser}
                  template={AppTemplate}
                  title="Delete Your Account"
                />}
            />
            <Route 
              path="/user/log"
              element={
                <AuthRouter
                  exact
                  path="/user/log"
                  component={ViewLogs}
                  template={AppTemplate}
                  title="View Activity Logs"
                />}
            />
            <Route 
              path="/account/:accountId/lists"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/lists"
                  component={SitesList}
                  template={AccountTemplate}
                  title="Sites List"
                  role="*"
                />}
            />
            <Route 
              path="/account/:accountId/sites/add-new/:id?"
              element={
                <AuthRouter
                  exact
                  path="/account/:accountId/sites/add-new/:id?"
                  component={AddSite}
                  template={AccountTemplate}
                  title="Add Sites"
                  role="*"
                />}
            />
            <Route 
              path="/accounts/subscriptions"
              element={
                <AuthRouter
                  exact
                  path="/accounts/subscriptions"
                  component={AccountSubscription}
                  template={AppTemplate}
                  title="Subscription List"
                />}
            />
            <Route 
              path="/terms"
              element={<PublicRouter
                exact
                path="/terms"
                component={TermsAndConditions}
                template={PublicTemplate}
                title="Terms & Conditions"
              />}
            />
            {/* <Route 
              path=""
              element={}
            /> */}
            <Route
              path="/welcome"
              element={
                <PublicRouter
                  exact
                  path="/welcome"
                  component={Welcome}
                  template={AuthTemplate}
                  title="Welcome"
                />
              }
            >
            </Route>

            <Route
                path="/signin"
                element={
                  <PublicRouter
                    exact
                    path="/signin"
                    component={SignIn}
                    template={AuthTemplate}
                    title="Sign In"
                  />
                }
              />
              <Route
                element={
                  <PublicRouter
                    component={PageNotFound}
                    template={PublicTemplate}
                    title="Page Not Found"
                  />}
              />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Elements>
  );
}

export default App;
