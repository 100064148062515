import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  TextField,
  Box,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import {
  CloudFunctions,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import { siteFormErrors } from "../../../../utils/constants";
import { makeStyles } from "@mui/styles";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";
import { useIsMobileView } from "../../../../hooks/useIsMobileView";
import {collection,addDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 400,
    color: "#000000 ",
  },
  textField: {
    margin: "12px 0",
  },
}));

const initialSiteState = {
  siteName: "",
  url: "",
};

const SiteImport = () => {
  const history = useNavigate();
  const classes = useStyles();
  const isMobileView = useIsMobileView();

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { authUser } = useContext(AuthContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);

  const [siteData, setSiteData] = useState(initialSiteState);
  const [errors, setErrors] = useState();
  const [submit, setSubmit] = useState();
  const [isAdd, setIsAdd] = useState(false);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setSiteData({
        ...siteData,
        [name]: value,
      });
    },
    [siteData]
  );

  const handleValidation = useCallback(() => {
    for (let data in siteData) {
      if (!siteData[data]) {
        if (data === "siteName" || data === "url") {
          setErrors(siteFormErrors[data].message);
          return true;
        }
      }
    }
    return false;
  }, [siteData]);

  // const insertSiteInfoToAccount = useCallback(
  //   async (sitesCollectionRef, authUserEmail) => {
  //     try {
  //       let finalObj = {
  //         ...siteData,
  //         image: siteData.image || "",
  //         createdAt: Date.now(),
  //         adminAccountEmail: authUserEmail,
  //         siteType: "Manual",
  //         isMyTemplate: false,
  //       };

  //       await sitesCollectionRef.set({ ...finalObj });
  //       const updateWebCheckResult = httpsCallable(CloudFunctions,
  //         "kwallCloud-updateWebCheckResult"
  //       );
  //       updateWebCheckResult({
  //         accountId: currentAccount.id,
  //         siteId: sitesCollectionRef.id,
  //         env: "external",
  //       });
  //     } catch (error) {
  //       setSubmit({ isSubmitting: false, result: "error" });
  //       console.log("___ ex", error);
  //     }
  //   },
  //   [currentAccount.id, siteData]
  // );

  const insertSiteInfoToAccount = useCallback(
    async (sitesCollectionRef, authUserEmail) => {
      try {
        let finalObj = {
          ...siteData,
          image: siteData.image || "",
          createdAt: Date.now(),
          adminAccountEmail: authUserEmail,
          siteType: "Manual",
          isMyTemplate: false,
        };

        const response = await addDoc(sitesCollectionRef,{ ...finalObj });
        const updateWebCheckResult = httpsCallable(
          CloudFunctions,
          "kwallCloud-updateWebCheckResult"
        );
        updateWebCheckResult({
          accountId: currentAccount.id,
          siteId: response.id,
          env: "external",
        });
      } catch (error) {
        setSubmit({ isSubmitting: false, result: "error" });
        console.log("___ ex", error);
      }
    },
    [currentAccount.id, siteData]
  );

  const insertSubSitesToAccount = useCallback(async () => {
    try {
      const importSubSitesToAccount = httpsCallable(
        CloudFunctions,
        "kwallCloud-importSubSitesToAccount"
      );
      const domain = siteData.url
        .replace("https://", "")
        .replace("http://", "");
      await importSubSitesToAccount({
        domain,
        accountId: currentAccount.id,
        siteName: siteData.siteName,
      });
    } catch (error) {
      setSubmit({ isSubmitting: false, result: "error" });
      console.log("___ ex", error);
    }
  }, [currentAccount.id, siteData.siteName, siteData.url]);

  const handleSubmit = useCallback(async () => {
    if (handleValidation()) return;
    const {
      user: { email: authUserEmail },
    } = authUser;
    try {
      setSubmit({ isSubmitting: true, result: null });
      // const sitesCollectionRef = Firestore.collection(
      //   `/accounts/${currentAccount.id}/sites`
      // ).doc();
      const sitesCollectionRef = collection(
        Firestore,
        `/accounts/${currentAccount.id}/sites`
      );
      insertSiteInfoToAccount(sitesCollectionRef, authUserEmail);
      if (isAdd) {
        insertSubSitesToAccount();
      }
      setSubmit({ isSubmitting: false, result: "success" });
    } catch (ex) {
      console.log("___ ex", ex);
      setSubmit({ isSubmitting: false, result: "error" });
    }
  }, [
    handleValidation,
    authUser,
    currentAccount.id,
    insertSiteInfoToAccount,
    isAdd,
    insertSubSitesToAccount,
  ]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: "Sites",
        active: false,
      },
      {
        to: null,
        text: "Import Site",
        active: true,
      },
    ]);
  }, [setBreadcrumb, currentAccount.id, currentAccount.name]);

  return (
    <Paper sx={{ padding: isMobileView ? "12px" : "24px" }}>
      {submit?.result ? (
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 400 }}>
            {submit.result === "success"
              ? "We are populating subdomains. It will take for a while..."
              : "Something went wrong. Please try again later."}
          </Typography>
          <Button variant="contained" onClick={() => history(-1)}>
            Back to Site List
          </Button>
        </Box>
      ) : (
        <Box>
          <Box className={classes.title}>Site Details</Box>
          {errors && (
            <Alert
              severity="error"
              dismissible={true}
              onDismiss={() => setErrors(null)}
            >
              {errors}
            </Alert>
          )}
          <TextField
            label="Site Name*"
            name="siteName"
            fullWidth
            value={siteData.siteName}
            onChange={handleChange}
            className={classes.textField}
          />
          <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <TextField
              label="Site URL*"
              name="url"
              fullWidth
              value={siteData.url}
              onChange={handleChange}
              className={classes.textField}
            />
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdd}
                  onClick={() => setIsAdd((prev) => !prev)}
                  name="isAdd"
                />
              }
              label="Also add all subdomains of this domain?"
            />
          </Box>

          <Box mt={3} sx={{ display: "flex", gap: "12px" }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={submit?.isSubmitting}
            >
              {submit?.isSubmitting && <Loader />}
              Import
            </Button>

            <Button
              type="cancel"
              variant="contained"
              onClick={() => history(-1)}
              style={{
                backgroundColor: "#F3F6F8",
                color: "#BD1B00",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default SiteImport;
